@font-face {
    font-family: 'VCR';
    font-style: normal;
    font-display: swap;
    src: url('../public/assets/VCR_OSD_MONO_1.001.ttf') format('opentype');
}

.App {
    background-color: black;
    width: 100%;
    min-height: 100vh;
    color: white;
    font-family: 'VCR';
}

.center-text {
    text-align: center;
}
#poster-mini {
    width: 70%;
}

.main {
    display: flex;
    justify-content: center;
    align-items: center;
}
.half {
    width: 50%;
}
.center-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button-like {
    border: 1px solid white;
    padding: 10px;
    width: fit-content;
}

.button-like:hover {
    background-color: white;
    color: black;
    cursor: pointer;
}

#bottomMargin {
    padding: 5% 0;
    font-size: 1rem;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .main {
        display: block;
    }

    #poster-mini {
        width: 90%;
    }

    .half {
        width: 100%;
    }

    #bottomMargin {
        padding: 10% 0;
    }
}
