:root {
    --mobile-card-top: 65px;
}

@font-face {
    font-family: 'VCR';
    font-style: normal;
    font-display: swap;
    src: url('../../public/assets/VCR_OSD_MONO_1.001.ttf') format('opentype');
}

@font-face {
    font-family: 'Nanum';
    font-style: normal;
    font-display: swap;
    src: url('../../public/assets/NanumPenScript-Regular.ttf')
        format('opentype');
}

html {
    height: 100vh;
}

.card-title {
    position: relative;
    width: 100%;
    font-size: 45px;
    text-transform: uppercase;
    font-family: 'VCR', sans-serif;
    text-align: center;
    color: white;
}

.card {
    height: 100%;
    width: 100%;
    max-width: 450px;
    position: relative;
    background-color: #000;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: #000;
    z-index: 0;
}

.card-input {
    border: none;
    color: #000;
    position: absolute;
    z-index: 2;
    text-align: center;
    background-color: transparent;
    font-size: 23px;
    /* font-family: 'Nanum Brush Script', cursive; */
    font-family: 'Nanum', sans-serif;
}

input::placeholder {
    color: black;
}

input:focus {
    outline: none;
}

.card-background {
    position: relative;
    display: flex;
    justify-content: center;
    height: auto;
    margin-top: 18px;
}

.card-background img {
    width: 95%;
    max-height: 268.14px;
}

.save-button {
    width: 100%;
    z-index: 1;
    text-align: center;
    padding: 10px 0;
}

.password-container {
    width: 100%;
    z-index: 1;
}

input[type='file'] {
    position: absolute;

    width: 0.1px;
    height: 0.1px;

    opacity: 0;
    overflow: hidden;
    z-index: -1;
}

label {
    display: inline-block;
    padding: 0.5em 0.75em;
    color: #999;
    font-size: inherit;
    line-height: normal;
    vertical-align: middle;
    background-color: #fdfdfd;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-bottom-color: #e2e2e2;
    border-radius: 0.25em;
}

.photo-modal,
.signature-draw-button {
    position: absolute;
    z-index: 4;
}

.image-upload-button {
    position: absolute;
}

.imageupload {
    position: absolute;
    z-index: 3;
    left: 11vw;
    height: 34.1vw;
    border: 2px solid grey;
    font-size: 14px;
    width: 26.8vw;
    background-color: #ffffff;
    bottom: 16vw;
}

#dropzone-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatar-editor-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10vw;
    background-color: #fff;
}

.zoom-in-out {
    display: flex;
    margin: 10px 0;
}

.editor-btn {
    font-size: 22px;
    margin: 0 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid black;
}

.imageuploaded {
    position: absolute;
    z-index: 5;
    left: 11vw;
    height: 34.1vw;
    width: 26.8vw;
    bottom: 16vw;
}

.signature-canvas {
    position: absolute;
    z-index: 5;
}
.warning {
    z-index: 1;
    color: white;
}

.password-container {
    color: white;
}

.card-title {
    font-size: 22px;
    padding-top: calc(var(--mobile-card-top) - 40px);
}

#card-name {
    width: 34vw;
    max-width: 200px;
    top: calc(var(--mobile-card-top) + 11vw);
    right: 12vw;
}

#card-dateOfBirth {
    top: calc(var(--mobile-card-top) + 11vw + 5vw);
    right: 11vw;
    width: 30vw;
}
#card-location {
    width: 30vw;
    top: calc(var(--mobile-card-top) + 11vw + 2 * 5vw);
    right: 11vw;
}

#card-dateOfIssue {
    top: calc(var(--mobile-card-top) + 11vw + 3 * 5vw);
    right: 11vw;
    width: 30vw;
}

.photo-modal {
    top: calc(var(--mobile-card-top));
}

#dropzone-container {
    width: 300px;
}

#close-button {
    width: 300px;
    background-color: #fff;
    text-align: center;
    border: 1px solid black;
    margin-top: 10px;
    padding: 15px 0;
}

#center-dropzone {
    margin-left: 11vw;
}

.signature-canvas canvas {
    width: 80vw;
    height: 15vw;
}

.full-length-button {
    font-size: 22px;
    padding: 10px;
    background-color: #fff;
    border: 5px solid black;
    margin-top: 10px;
}

.signature-canvas {
    left: 9vw;
    top: calc(var(--mobile-card-top) + 20vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.signature-image {
    position: absolute;
    width: 27vw;
    left: 11.3vw;
    bottom: 10vw;
}

.signature-draw-button span {
    font-size: 30px;
    position: absolute;
    left: 11vw;
    width: 26.5vw;
    text-align: right;
    font-family: 'Nanum', sans-serif;
    bottom: 7vw;
}

.save-button button {
    font-size: 25px;
    background-color: transparent;
    border: 1px solid white;
    padding: 0 15px 5px 15px;
    color: white;
    font-family: 'VCR';
}

.warning {
    font-size: 14px;
    top: calc(var(--mobile-card-top) + 13.5vw + 3 * 5vw + 54vw);
    width: 90%;
    padding: 10px 20px;
    font-family: 'Nanum Gothic', sans-serif;
}

.password-container {
    top: calc(var(--mobile-card-top) + 13.5vw + 3 * 5vw + 31vw);
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;
    padding: 10px 20px;
    width: 90%;
}

#password-input {
    padding: 6px 5px;
    font-size: 14px;
    font-family: 'Nanum Gothic', sans-serif;
    margin-bottom: 5px;
    border: 1px solid white;
    width: 95%;
    background-color: transparent;
    color: white;
}
#password-input::placeholder {
    color: white;
}

#password-input:focus {
    background-color: #fff;
    color: #000;
}

.card-line {
    width: 90%;
    left: 5%;
    border-bottom: 1px solid #fff;
    z-index: 3;
    margin: 15px auto;
}

#copyright {
    font-size: 0.8rem;
    text-align: center;
    color: white;
    width: 100%;
    font-family: 'VCR';
}

@media only screen and (min-width: 450px) {
    .card {
        margin: auto;
    }
    #card-name {
        width: 160px;
        top: calc(var(--mobile-card-top) + 50px);
        right: 50px;
    }

    #card-dateOfBirth {
        top: calc(var(--mobile-card-top) + 50px + 23px);
        right: 50px;
        width: 135px;
    }
    #card-location {
        width: 135px;
        top: calc(var(--mobile-card-top) + 50px + 2 * 23px);
        right: 50px;
    }

    #card-dateOfIssue {
        top: calc(var(--mobile-card-top) + 50px + 3 * 23px);
        right: 50px;
        width: 135px;
    }

    .imageupload {
        left: 48px;
        height: calc(123px * 1.28);
        width: 123px;
        bottom: 72px;
    }

    .imageuploaded {
        left: 48px;
        height: calc(123px * 1.28);
        width: 123px;
        bottom: 72px;
    }

    #center-dropzone {
        margin-left: 75px;
    }

    .signature-draw-button span {
        font-size: 30px;
        left: 48px;
        width: 123px;
        bottom: 38px;
    }

    .signature-canvas {
        width: 220px;
        left: 115px;
        top: calc(var(--mobile-card-top) + 70px);
    }

    .signature-canvas canvas {
        width: 500px;
        height: calc(500px / 5.3);
    }

    .signature-image {
        width: 123px;
        left: 48px;
        bottom: 44px;
    }

    .avatar-editor-container {
        margin-left: 75px;
    }
}
