@font-face {
    font-family: 'VCR';
    font-style: normal;
    font-display: swap;
    src: url('../../public/assets/VCR_OSD_MONO_1.001.ttf') format('opentype');
}

@font-face {
    font-family: 'Pretendard';
    font-style: normal;
    font-display: swap;
    src: url('../../public/assets/Pretendard-Medium.otf') format('opentype');
}

.line {
    width: 90%;
    margin: auto;
    border-bottom: 1px solid #fff;
}

.short-line {
    width: 25%;
    margin: auto;
    border-bottom: 1px solid #fff;
}

.user {
    margin: auto;
    background-color: black;
    width: 100%;
    height: 100%;
    font-family: VCR, monospace;
    color: #fff;
    max-width: 600px;
}

.intro,
.name,
.mbti,
.fortune-music {
    padding: 5%;
}

#logo {
    width: 50px;
    height: auto;
    vertical-align: middle;
}

.exhibition-title span {
    font-size: 26px;
    vertical-align: middle;
}

#booking {
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
}

#poster {
    width: 100%;
    height: auto;
}

#name-display {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: calc(50px - 5%);
}
#name-value {
    width: 37.5%;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
}
.container-title {
    font-size: 25px;
}

#mbti-display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 160px;
}
#mbti-value {
    border: 2px solid white;
    width: fit-content;
    font-size: 80px;
    padding: 0 10px 10px 10px;
}

#thumbnail-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#thumbnail {
    width: 300px;
    height: auto;
}

#no-thumbnail {
    font-size: 13px;
    text-align: center;
    margin: 10px 0;
}

.lp-container {
    border: 1px solid white;
    padding: 50px 0 0 0;
}

#lp-display {
    display: flex;
    justify-content: center;
    position: relative;
}

#lp {
    animation: spin infinite 0.8s linear;
}

#lp-stroke {
    position: absolute;
    right: 15%;
}

#play-button {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    padding: 30px 10px 10px 0;
}

#play-button button {
    background-color: transparent;
    border: 1px solid white;
    padding: 10px 15px 5px 15px;
    color: #fff;
    font-size: 15px;
}

#play-image {
    width: 15px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#music-comment {
    font-size: 15px;
    font-family: 'Pretendard';
}

#music-comment span {
    margin-left: 11px;
}
